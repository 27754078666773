import loadScriptAsync from './utils/load-script-async';
import { waitGlobalDependency } from './utils/wait-global-dependency';

export default class {
    constructor() {
        this.url = '//code.jivosite.com/script/widget/CsuOjJvln3';
        this.btn = document.getElementById('j_button');
        this.init();
        this.handlers();
    }

    async init() {
        for (let i in window.localStorage || {}) {
            if (window.localStorage.hasOwnProperty(i)) {
                let s = new RegExp('jv_messages_items_.*', 'i');
                if (s.test(i)) {
                    await loadScriptAsync(this.url);
                    this.removeFakeBtn();
                }
            }
        }
    }

    handlers() {
        this.btn.addEventListener('click', () => {
            // const child_node = this.btn.querySelector('div');
            // child_node.classList.add('loading');
            this.startChat();
        });
    }

    async startChat() {
        await loadScriptAsync(this.url);
        await waitGlobalDependency('jivo_api');
        window.jivo_api.open({ start: 'chat' });
        this.removeFakeBtn();
    }

    removeFakeBtn() {
        this.btn.parentNode.removeChild(this.btn);
    }
}
