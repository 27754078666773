export default (src) => {
    return new Promise((resolve) => {
        const tag = document.createElement('script');
        tag.src = src;
        tag.async = true;

        tag.onload = () => {
            resolve();
        };

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    });
};
