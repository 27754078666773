export default class {
    constructor(els, offset) {
        this.els = els;
        this.offset = offset;

        this.init();
    }

    init() {
        Array.prototype.forEach.call(this.els, (el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                this.onClick(el);
            });
        });
    }

    onClick(el) {
        const target = document.querySelector(el.getAttribute('href'));

        if (target) {
            const y = target.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
                top: y - this.offset,
                behavior: 'smooth',
            });
        }
    }
}
