import 'core-js/stable';
import 'regenerator-runtime/runtime';
import $ from 'jquery';
import 'slick-carousel';
import 'magnific-popup';
import { priceFormat } from './utils/formats';

import '../scss/';

import ToggleSidebarNav from './toggle-sidebar-nav';
import ScrollTo from './scroll-to';
import JivoSite from './jivosite';

window.jQuery = $;
window.$ = $;
global.jQuery = $;
global.$ = $;

document.addEventListener('DOMContentLoaded', async () => {
    new ToggleSidebarNav();
    new ScrollTo(
        document.querySelectorAll('a.anchor-js'),
        document.querySelector('.main-header').offsetHeight
    );

    // Табы
    // TODO: инициализировать в цикле
    const tabContainer = document.querySelector('[data-js-tab-container]');

    if (tabContainer) {
        const { default: Tabs } = await import('./tabs');
        new Tabs({
            container: tabContainer,
            tabSelector: '[data-js-tab]',
            panelSelector: '[data-js-tab-panel]',
            activeTabClass: 'tabs-header__item_active',
        });
    }

    // Ловушка для спам-ботов
    const ajaxForms = document.querySelectorAll('.ajax_form');

    if (ajaxForms) {
        const { default: HoneyPod } = await import('./honey-pod');
        new HoneyPod(ajaxForms);
    }

    new JivoSite();

    // mask
    const phoneInputs = document.querySelectorAll('input[name="phone"]');

    if (phoneInputs) {
        const { default: Inputmask } = await import('inputmask');
        Array.prototype.forEach.call(phoneInputs, (el) => {
            Inputmask({ mask: '+7 (999) 999-99-99' }).mask(el);
        });
    }

    const totalNode = document.querySelector('[data-calc-total]');
    const countNode = document.querySelector('[data-calc-count-input]');
    const priceNode = document.querySelector('[data-calc-price]');

    if (totalNode && countNode && priceNode) {
        if (window.productCalcConfig) {
            const { default: ProductCalcPrice } = await import(
                './product-price-calc'
            );

            new ProductCalcPrice({
                countNode,
                totalNode,
                priceNode,
                offerPriceBtnNode: document.querySelector(
                    '[data-calc-offer-price-btn]'
                ),
                cartActionBtnsNode: document.querySelector(
                    '[data-calc-cart-action-btns]'
                ),
                priceConfig: window.productCalcConfig,
            });
        } else {
            // TODO: рефакторинг
            function simpleCalc(count) {
                const price = priceNode.innerHTML.replace(/ /g, '');
                const sum = priceFormat(count * price);
                totalNode.innerHTML = sum;
            }

            countNode.addEventListener('input', (e) => {
                let value = parseInt(e.target.value, 10);

                if (!value || value <= 0) {
                    countNode.value = 1;
                    value = 1;
                }

                simpleCalc(value);
            });

            Array.prototype.forEach.call(
                document.querySelectorAll('.color-thumb'),
                (el) => {
                    el.addEventListener('click', () => {
                        simpleCalc(countNode.value);
                    });
                }
            );
        }
    }

    const accordions = document.querySelectorAll('[data-js-accordion]');

    if (accordions) {
        const { default: Accordion } = await import('./accordion');
        Array.prototype.forEach.call(accordions, (el) => {
            new Accordion({
                wrapEl: el,
                toggleEl: '[data-js-accordion-toggle]',
                hiddenEL: '[data-js-accordion-hidden]',
                activeClass: 'accordion-item_active',
            });
        });
    }

    if (ajaxForms) {
        const { default: HoneyPod } = await import('./honey-pod');
        new HoneyPod(ajaxForms);
    }

    class CatalogNavigation {
        constructor() {
            this.trigger = document.querySelector(
                '[data-catalog-dropdown-trigger]'
            );
            this.wrapper = document.querySelector(
                '[data-catalog-dropdown-wrapper]'
            );
            this.closeBtn = document.querySelector(
                '[data-catalog-dropdown-close]'
            );

            this.status = false;
            this.events();
        }

        events() {
            this.trigger.addEventListener('mouseover', this.open.bind(this));
            this.closeBtn.addEventListener('click', this.toggle.bind(this));

            window.addEventListener('click', (e) => {
                if (!this.wrapper.contains(e.target)) {
                    this.close();
                }
            });
        }

        toggle() {
            this.wrapper.classList.toggle('d-block');
        }

        open() {
            this.wrapper.classList.add('d-block');
        }

        close() {
            this.wrapper.classList.remove('d-block');
        }
    }

    new CatalogNavigation();

    // data-catalog-dropdown-trigger
    // data-catalog-dropdown-wrapper

    // LEGACYYY
    await import('./legacy.js');
});
