export default class {
    constructor() {
        this.btn = document.querySelector('.toggle-menu');
        this.sidebar = document.querySelector('.sidebar');

        this.btnActiveClass = 'toggle-menu_animate';
        this.sidebarActiveClass = 'sidebar_active';

        this.state = false;

        this.events();
    }

    events() {
        this.btn.addEventListener('click', () => {
            this.toggle();
        });
    }

    toggle() {
        this.state = !this.state;

        if (this.state) {
            this.btn.classList.add(this.btnActiveClass);
            this.sidebar.classList.add(this.sidebarActiveClass);
            return;
        }

        this.btn.classList.remove(this.btnActiveClass);
        this.sidebar.classList.remove(this.sidebarActiveClass);
    }
}
