const DEFAULT_INTERVAL = 250;
const DEFAULT_TIMEOUT = 1250;
/**
 * @description
 * @param {String} [key=null] // TODO: adding nested path
 * @param {Object} [scope=null]
 * @param {Number} [interval=DEFAULT_INTERVAL]
 * @param {Number} [timeout=DEFAULT_TIMEOUT]
 * @resolve
 * @reject Error
 */
export const waitGlobalDependency = (
    key,
    scope = null,
    interval = DEFAULT_INTERVAL,
    timeout = DEFAULT_TIMEOUT
) =>
    new Promise((resolve, reject) => {
        let acc = 0;

        if (!scope) {
            if (!window) {
                return reject(new Error('scope is not defined'));
            }

            scope = window;
        }

        (function wait() {
            if (scope.hasOwnProperty(key)) {
                return resolve();
            }

            if (acc >= timeout) {
                return reject(new Error(`Global dependency not found: ${key}`));
            }

            acc += interval;

            setTimeout(wait, acc);
        })();
    });
